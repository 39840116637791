import styled, { css } from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightTextColor, lineColor, darkBaseColor, darkPrimaryColor, primaryTextColor, primaryLightTextColor, primaryLineColor, darkSecondaryColor, secondaryTextColor, secondaryLightTextColor, secondaryLineColor, darkAccentColor, accentLightTextColor, accentLineColor } from 'views/services/color-scheme';

interface Columns {
  columns: number;
}

const labelWidth = '360px';
const headingWidth = '240px';
const columnWidth = '280px';

export const Root = styled.div`
  position: relative;
`;

export const Item = styled.div`
  position: relative;
`;

export const Ingredient = styled.div`
  position: relative;
`;

export const Row = styled.div<Columns>`
  width: calc(${labelWidth} + ${p => p.columns} * ${columnWidth});
  &.category {
    z-index: 2;
    position: sticky;
    top: calc(${baseFontSize} * 0.8 + 8px * 2);
  }
  display: flex;
  justify-content: start;
`;

const ingredient = (i: number) => css`
  &.ingredient-${i} {
    background-color: ${darkBaseColor};
    &::after {
      border-color: ${lineColor};
    }
    & > h4 {
      color: ${textColor};
      flex: 0 0 calc(${headingWidth} - 16px * ${i});
      width: calc(${headingWidth} - 16px * ${i});
      padding-left: calc(16px * ${i});
    }
    & > div {
      color: ${lightTextColor};
    }
  }
`;

export const Label = styled.div`
  flex: 0 0 ${labelWidth};
  z-index: 1;
  position: sticky;
  left: 0;
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 0.8);
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: end;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -0.5px;
    bottom: 0;
    border-right: 1px solid transparent;
    pointer-events: none;
  }
  & > h2, & > h3, & > h4 {
    flex: 0 0 ${headingWidth};
    width: ${headingWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div {
    white-space: nowrap;
  }
  &.category {
    background-color: ${darkPrimaryColor};
    &::after {
      border-color: ${primaryLineColor};
    }
    & > h2 {
      color: ${primaryTextColor};
    }
    & > div {
      color: ${primaryLightTextColor};
    }
  }
  &.item {
    background-color: ${darkSecondaryColor};
    &::after {
      border-color: ${secondaryLineColor};
    }
    & > h3 {
      color: ${secondaryTextColor};
    }
    & > div {
      color: ${secondaryLightTextColor};
    }
  }
  ${[...Array(5)].map((_, i) => ingredient(i + 1))}
`;

export const Metrics = styled.div`
  width: ${columnWidth};
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 0.8);
  text-align: right;
  z-index: 0;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -0.5px;
    right: 0;
    bottom: 0;
    border-left: 1px solid transparent;
    pointer-events: none;
  }
  &.category {
    background-color: ${darkPrimaryColor};
    color: ${primaryLightTextColor};
    &::after {
      border-color: ${primaryLineColor};
    }
    &.sum, &.average {
      background-color: ${darkAccentColor};
      color: ${accentLightTextColor};
      &::after {
        border-color: ${accentLineColor};
      }
    }
  }
  &.item {
    background-color: ${baseColor};
    color: ${textColor};
    &::after {
      border-color: ${lineColor};
    }
  }
  &.ingredient {
    background-color: ${darkBaseColor};
    color: ${textColor};
    &::after {
      border-color: ${lineColor};
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    flex: 1;
    padding: 8px 16px;
    white-space: nowrap;
  }
`;

export const Weekly = styled.div`
  display: flex;
`;

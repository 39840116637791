import React, { FC, useState, useEffect } from 'react';

import { MenuCategory } from 'models/entities/menu-category';
import { DishCategory } from 'models/entities/dish-category';
import { DishItemAnalysisCollection } from 'models/entities/dish-item-analysis';
import { OrderedMenuItemDatasetCollection } from 'models/entities/ordered-menu-item';
import { Column } from '../../models/column';
import { Model } from './index.model';

import { showUnit, showNumber } from 'views/services/helpers';
import { Root, Metrics, Item, Ingredient, Row, Label, Weekly } from './index.styled';

interface Props {
  columns: Column[];
  menuCategory: MenuCategory;
  dishCategory: DishCategory;
  analyses?: DishItemAnalysisCollection[];
  datasets?: OrderedMenuItemDatasetCollection;
}

const Category: FC<Props> = ({ columns, menuCategory, dishCategory, analyses, datasets }) => {

  const [model, setModel] = useState(new Model({ columns, menuCategory, dishCategory }));

  function init() {
    const analysis = analyses?.find(it => it.menuCategoryId === menuCategory.id)?.documents.filter(it => it.category.id === dishCategory.id) ?? [];
    setModel(model.init({ columns, analysis }));
  }

  function load() {
    if (!datasets) return;
    const timer = setTimeout(() => setModel(model => model.load({ datasets })), 0);
    return () => clearTimeout(timer);
  }

  useEffect(init, [analyses, columns]);
  useEffect(load, [datasets]);

  return (
    <Root>
      {!!model.analysis.length && (
        <>
          <Row columns={model.columns.length} className="category">
            <Label className="category">
              <h2>{`${menuCategory.original.name} : ${dishCategory.original.name}`}</h2>
              <div>Unit</div>
            </Label>
            {model.columns.map((column, i) => (
              <Metrics key={i} className={`${column.type} category`}>
                <div>Quantity</div>
                <div>Subtotal</div>
                <div>Total</div>
              </Metrics>
            ))}
          </Row>
          {model.analysis.map(dishItem => (
            <Item key={dishItem.id}>
              <Row columns={model.columns.length}>
                <Label className="item">
                  <h3>{dishItem.name}</h3>
                  <div>{showUnit(dishItem.unit, dishItem.ingredientItem?.unitSymbol)}</div>
                </Label>
                {dishItem.statistics?.weeklies.map((weekly, i) => (
                  <Weekly key={i}>
                    {weekly.dailies.map((daily, j) => (
                      <Metrics key={j} className="item date">
                        <div>{showNumber(daily.quantity)}</div>
                        <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                        <div>{showUnit(daily.total, dishItem.ingredientItem?.unitSymbol)}</div>
                      </Metrics>
                    ))}
                    <Metrics className="item total">
                      <div>{showNumber(weekly.sum.quantity)}</div>
                      <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                      <div>{showUnit(weekly.sum.total, dishItem.ingredientItem?.unitSymbol)}</div>
                    </Metrics>
                    <Metrics className="item average">
                      <div>{showNumber(weekly.average.quantity)}</div>
                      <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                      <div>{showUnit(weekly.average.total, dishItem.ingredientItem?.unitSymbol)}</div>
                    </Metrics>
                  </Weekly>
                ))}
              </Row>
              {dishItem.ingredientItem?.ingredients.documents.map(ingredient => (
                <Ingredient key={ingredient.id}>
                  <Row columns={model.columns.length}>
                    <Label className="ingredient-1">
                      <h4>{ingredient.dishItem.name}</h4>
                      <div>{showUnit(ingredient.usage, dishItem.ingredientItem?.unitSymbol)}</div>
                    </Label>
                    {ingredient.statistics?.weeklies.map((weekly, i) => (
                      <Weekly key={i}>
                        {weekly.dailies.map((daily, j) => (
                          <Metrics key={j} className="ingredient date">
                            <div>{showNumber(daily.quantity)}</div>
                            <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                            <div></div>
                          </Metrics>
                        ))}
                        <Metrics className="ingredient total">
                          <div>{showNumber(weekly.sum.quantity)}</div>
                          <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                          <div></div>
                        </Metrics>
                        <Metrics className="ingredient average">
                          <div>{showNumber(weekly.average.quantity)}</div>
                          <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                          <div></div>
                        </Metrics>
                      </Weekly>
                    ))}
                  </Row>
                  {ingredient.dishItem.ingredientItem?.ingredients.documents.map(ingredient2 => (
                    <Ingredient key={ingredient2.id}>
                      <Row columns={model.columns.length}>
                        <Label className="ingredient-2">
                          <h4>{ingredient2.dishItem.name}</h4>
                          <div>{showUnit(ingredient2.usage, dishItem.ingredientItem?.unitSymbol)}</div>
                        </Label>
                        {ingredient2.statistics?.weeklies.map((weekly, i) => (
                          <Weekly key={i}>
                            {weekly.dailies.map((daily, j) => (
                              <Metrics key={j} className="ingredient date">
                                <div>{showNumber(daily.quantity)}</div>
                                <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                <div></div>
                              </Metrics>
                            ))}
                            <Metrics className="ingredient total">
                              <div>{showNumber(weekly.sum.quantity)}</div>
                              <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                              <div></div>
                            </Metrics>
                            <Metrics className="ingredient average">
                              <div>{showNumber(weekly.average.quantity)}</div>
                              <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                              <div></div>
                            </Metrics>
                          </Weekly>
                        ))}
                      </Row>
                      {ingredient2.dishItem.ingredientItem?.ingredients.documents.map(ingredient3 => (
                        <Ingredient key={ingredient3.id}>
                          <Row columns={model.columns.length}>
                            <Label className="ingredient-3">
                              <h4>{ingredient3.dishItem.name}</h4>
                              <div>{showUnit(ingredient3.usage, dishItem.ingredientItem?.unitSymbol)}</div>
                            </Label>
                            {ingredient3.statistics?.weeklies.map((weekly, i) => (
                              <Weekly key={i}>
                                {weekly.dailies.map((daily, j) => (
                                  <Metrics key={j} className="ingredient date">
                                    <div>{showNumber(daily.quantity)}</div>
                                    <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                    <div></div>
                                  </Metrics>
                                ))}
                                <Metrics className="ingredient total">
                                  <div>{showNumber(weekly.sum.quantity)}</div>
                                  <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                  <div></div>
                                </Metrics>
                                <Metrics className="ingredient average">
                                  <div>{showNumber(weekly.average.quantity)}</div>
                                  <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                  <div></div>
                                </Metrics>
                              </Weekly>
                            ))}
                          </Row>
                          {ingredient3.dishItem.ingredientItem?.ingredients.documents.map(ingredient4 => (
                            <Ingredient key={ingredient4.id}>
                              <Row columns={model.columns.length}>
                                <Label className="ingredient-4">
                                  <h4>{ingredient4.dishItem.name}</h4>
                                  <div>{showUnit(ingredient4.usage, dishItem.ingredientItem?.unitSymbol)}</div>
                                </Label>
                                {ingredient4.statistics?.weeklies.map((weekly, i) => (
                                  <Weekly key={i}>
                                    {weekly.dailies.map((daily, j) => (
                                      <Metrics key={j} className="ingredient date">
                                        <div>{showNumber(daily.quantity)}</div>
                                        <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                        <div></div>
                                      </Metrics>
                                    ))}
                                    <Metrics className="ingredient total">
                                      <div>{showNumber(weekly.sum.quantity)}</div>
                                      <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                      <div></div>
                                    </Metrics>
                                    <Metrics className="ingredient average">
                                      <div>{showNumber(weekly.average.quantity)}</div>
                                      <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                      <div></div>
                                    </Metrics>
                                  </Weekly>
                                ))}
                              </Row>
                              {ingredient4.dishItem.ingredientItem?.ingredients.documents.map(ingredient5 => (
                                <Ingredient key={ingredient5.id}>
                                  <Row columns={model.columns.length}>
                                    <Label className="ingredient-5">
                                      <h4>{ingredient5.dishItem.name}</h4>
                                      <div>{showUnit(ingredient5.usage, dishItem.ingredientItem?.unitSymbol)}</div>
                                    </Label>
                                    {ingredient5.statistics?.weeklies.map((weekly, i) => (
                                      <Weekly key={i}>
                                        {weekly.dailies.map((daily, j) => (
                                          <Metrics key={j} className="ingredient date">
                                            <div>{showNumber(daily.quantity)}</div>
                                            <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                            <div></div>
                                          </Metrics>
                                        ))}
                                        <Metrics className="ingredient total">
                                          <div>{showNumber(weekly.sum.quantity)}</div>
                                          <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                          <div></div>
                                        </Metrics>
                                        <Metrics className="ingredient average">
                                          <div>{showNumber(weekly.average.quantity)}</div>
                                          <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                                          <div></div>
                                        </Metrics>
                                      </Weekly>
                                    ))}
                                  </Row>
                                </Ingredient>
                              ))}
                            </Ingredient>
                          ))}
                        </Ingredient>
                      ))}
                    </Ingredient>
                  ))}
                </Ingredient>
              ))}
            </Item>
          ))}
        </>
      )}
    </Root>
  );

};

export { Category };

import React, { useState, useEffect, useRef } from 'react';

import { Model, DataToSearch } from './index.model';

import { useAuth } from 'views/services/auth';
import { useMemory } from 'views/services/memory';
import { useBusinessCode } from 'views/services/hooks';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { ContentLoading } from 'views/components/content-loading';
import { Header } from 'views/components/header';
import { PeriodToolItem } from 'views/components/period-tool-item';
import { ColumnHeaders } from './views/column-headers';
import { Category } from './views/category';
import { Root, Content, Frame, HeaderRow, Tools, Series, Department } from './index.styled';

const Preparation = () => {

  const auth = useAuth();
  const memory = useMemory();
  const code = useBusinessCode();
  const [model, setModel] = useState(new Model());
  const frame = useRef<HTMLDivElement>(null);

  function search(data: DataToSearch) {
    setModel(model.startSearching());
    model.search(data).then(it => setModel(it));
  }

  function exportReport() {
  }

  function onChat() {
  }

  function watchAuth() {
    if (!auth.ok) return;
    if (!code) return;
    const businessScope = memory.get('business-scope');
    businessScope ? setModel(model.init(businessScope)) : model.readBusiness(code).then(model => setModel(model));
  }

  function watchBusiness() {
    if (!model.business) return;
    model.read().then(model => setModel(model));
  }

  function watchDatasets() {
    if (!model.datasets) return;
    frame.current && frame.current.scrollTo(0, 0);
  }

  useEffect(watchAuth, [auth.ok, code]);
  useEffect(watchBusiness, [model.business]);
  useEffect(watchDatasets, [model.datasets]);

  return (
    <Root>
      <Header mode={'business'} businesses={model.businesses} business={model.business} onExportReport={() => exportReport()} onChat={onChat} disabledAction={model.disabledAction} />
      {!model.business || !model.analyses ? <InterfaceLoading /> : (
        <Content>
          <ContentLoading loading={model.searching}>
            <Frame ref={frame}>
              <HeaderRow columns={model.columns.length}>
                <Tools>
                  <PeriodToolItem period={model.conditions.period} onEdit={period => search({ period })} />
                </Tools>
                <Series>
                  <ColumnHeaders columns={model.columns} salesAnalysis={model.salesAnalysis} dailySales={model.dailySales} currency={model.business.currency} />
                </Series>
              </HeaderRow>
              {!model.business.menuCategories.documents.length ? <NoInterfaceData>No Menu Category</NoInterfaceData> : model.business.menuCategories.documents.map(menuCategory => (
                <Department key={menuCategory.id}>
                  {!menuCategory.dishCategories.documents.length ? <NoInterfaceData>No Dish Category</NoInterfaceData> : menuCategory.dishCategories.documents.map(dishCategory => (
                    <Category key={dishCategory.id} columns={model.columns} menuCategory={menuCategory} dishCategory={dishCategory} analyses={model.analyses} datasets={model.datasets} />
                  ))}
                </Department>
              ))}
            </Frame>
          </ContentLoading>
        </Content>
      )}
    </Root>
  );

}

export default Preparation;